<div
    id="mens-alert-dialog"
    *ngIf="alert$ | async as alert"
>
    <span>{{ alert }}</span>
</div>
<components-b4a-header
    [menu]="menu"
    [logo]="logo"
    [closeButton]="closeButton"
></components-b4a-header>
<!-- <mens-header></mens-header> -->
<router-outlet></router-outlet>
<components-b4a-footer [linksB4a]="linksB4a" [logoBrand]="logoFooter" [linksBrand]="linksBrand"></components-b4a-footer>
