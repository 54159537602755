<div id="results-component">
    <div class="wrapper">
        <h2 class="wrapper__title">Resultados</h2>
        <h3 class="wrapper__subtitle">
            Compare o resultado de quem já usou os produtos da Men's:
        </h3>
        <ng-container *ngIf="loading; else loadedProducts">
            <div class="content-loading">
                <ngx-skeleton-loader
                    [count]="getSkeletonCount()"
                    [theme]="getSkeletonTheme()"
                ></ngx-skeleton-loader>
            </div>
        </ng-container>

        <ng-template #loadedProducts>
            <div
                class="content"
                *ngIf="products.length > 0; else noExistsPublishedProducts"
            >
                <components-b4a-carousel
                    #myCarousel
                    [inputs]="isMobile ? carouselTileMobile : carouselTile"
                    [dataSource]="products"
                    (onMove)="onCarouselMove($event)"
                >
                    <b4a-tile *b4aCarouselDef="let item; let i = index">
                        <mens-result-card [product]="item"></mens-result-card>
                    </b4a-tile>
                    <button
                        B4aCarouselPrev
                        class="prev"
                        [style.opacity]="myCarousel.isFirst ? 0.5 : 1"
                    >
                        <img
                            src="../../../assets/img/svg/outline-previous-arrow.svg"
                            alt="botão voltar"
                        >
                    </button>
                    <button
                        B4aCarouselNext
                        class="next"
                        [style.opacity]="myCarousel.isLast ? 0.5 : 1"
                    >
                        <img
                            src="../../../assets/img/svg/outline-next-arrow.svg"
                            alt="botão próximo"
                        >
                    </button>
                    <ul
                        class="dots"
                        B4aCarouselPoint
                        *ngIf="points?.length > 0"
                    >
                        <li
                            *ngFor="let i of points; let index"
                            [class.active]="i==myCarousel.activePoint"
                            (click)="myCarousel.moveTo(index)"
                        ></li>
                    </ul>
                </components-b4a-carousel>
            </div>

            <ng-template #noExistsPublishedProducts>
                <div class="without-publics-products">
                    <span>Não há produtos publicados.</span>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>
