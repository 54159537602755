import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mens-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.scss']
})
export class OurProductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
