import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ResponseNewletter } from '@infrab4a/components-b4a';
import { Product, ProductRepository, ShopSettings, ShopSettingsRepository, Where } from '@infrab4a/connect';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandLeadService } from 'src/app/core/services/api/brand-lead.service';
import { ResizeAppService } from 'src/app/core/services/resize-application.service';
import { Banner } from 'src/app/shared/models/banner';
import { LeadSourceEnum } from 'src/app/shared/models/lead.model';
import { ProductReviewModel } from 'src/app/shared/models/product-review-model';
import { ProductModel } from 'src/app/shared/models/product.model';
import { Result } from 'src/app/shared/models/result.model';
import { Utils } from 'src/app/shared/utils/Utils';
import { environment as ENV } from 'src/environments/environment';

import { LeadModel } from './../../shared/models/lead.model';



@Component({
  selector: 'mens-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  products: Product[] = [];
  reviewedProducts: ProductModel[];
  reviewPoints: number[];
  isMobile: boolean;
  loading: boolean;
  isProduction = false;
  innerWidth: number;
  banners: Banner[];
  display: boolean = true;
  loadingReviewedProducts: boolean;

  closeResult: string = '';
  
  instagram = {
    username: 'use.mens',
    token: ENV.instaToken,
  };
  companyValues = [
    {
      description: 'Para todas as peles e cabelos',
      svgPath: 'assets/img/our_value_1.svg',
    },
    {
      description: 'Vegano e cruelty free',
      svgPath: 'assets/img/our_value_2.svg',
    },
    {
      description: 'Dermatologicamente testado',
      svgPath: 'assets/img/our_value_3.svg',
    },
    {
      description: 'Sem parabenos',
      svgPath: 'assets/img/our_value_4.svg',
    },
    {
      description: 'Ativos tecnológicos como M8VIT',
      svgPath: 'assets/img/our_value_5.svg',
    },
  ];
  projectId: string;
  source: string;

  formLead: FormGroup;
  loadingNewsletter: boolean;

  submitted = false;

  show = true;

  namePattern = '^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$';

  emailPattern = '^[a-zA-Z]+([\\.\\_\\-]?[a-zA-Z0-9])*@[a-zA-Z0-9]{2,}(\\.[a-zA-Z]{2,4}){1,2}$';

  user_email = '';

  @ViewChild('content') myModal: any;

  results: Result[] = [];

  constructor(
    @Inject('ProductRepository') private _fireStore: ProductRepository,
    private _resizeAppService: ResizeAppService,
    private meta: Meta,
    private titleService: Title,
    private modalService: NgbModal,
    private _brandLeadService: BrandLeadService,
    private fb: FormBuilder,
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository
  ) {
    this.isProduction = ENV.production;

    this.isMobile = this._resizeAppService.isMobileWidthSize;
    this.innerWidth = window.innerWidth;
    this.loading = false;
  }

  ngAfterViewInit(){
    this.open(this.myModal);
    this.initForm();
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.projectId = ENV.firebase.projectId;
    this.source = "Malmo";
    this.getProducts();
    this.mountResult();
    this.getReviewedProducts();
    this._resizeAppService
      .onResize()
      .subscribe((innerWidth) => (this.innerWidth = innerWidth));
    
      this.titleService.setTitle("Men's: Produtos de cabelo, barba corpo e pele para homens");
      this.meta.addTag({
        name: 'author',
        content: 'B4A'
      });
      this.meta.updateTag({
        name: 'description',
        content:
          `A Men's é a marca aliada no cuidado pessoal dos homens que prezam pelo bem-estar. Oferece produtos de cabelo, barba, rosto e corpo de alta qualidade e eficácia!`
      });

      const mensDocument = await this.shopSettingsRepository.get({
        id: 'MENS'
      }); 
      
      this.loadBanners(mensDocument);
     
  }

  loadBanners(mensDocument: ShopSettings) {
    this.banners = mensDocument.sections.map((banner) => ({
      image: banner.desktopImage,
      mobileImage: banner.mobileImage,
      path: banner.link,
      alt: banner.altText,
      id: banner.id
    }));
  }

  handleSlideToSection(section: string): void {
    Utils.slideToSection(section);
  }

  private async getProducts(): Promise<void> {
    try {
      this.loading = true;

      const value = `Men's`;
      const limit = 7;
      const productsResponse = await this._fireStore.find({
        filters: {
          brand: {
            operator: Where.EQUALS,
            value
          },
          published: {
            operator: Where.EQUALS,
            value: true
          },
          stock: {
            quantity: {
              operator: Where.GT,
              value: 0
            }
          }
        }
      });

      this.products = productsResponse.data;

      this.loading = false;
    } catch (err) {
      console.error(err);
      this.loading = false;
    }
  }

  getSkeletonTheme(): Object {
    return {
      width: '210px',
      height: '315px',
      'border-radius': '4px',
      'background-color': '#f2f2f2',
    };
  }

  getSkeletonCount(): number {
    if (this.innerWidth <= 425) {
      return 1;
    } else if (this.innerWidth > 425 && this.innerWidth <= 585) {
      return 2;
    } else if (this.innerWidth > 585 && this.innerWidth <= 768) {
      return 3;
    } else if (this.innerWidth > 768 && this.innerWidth <= 1366) {
      return 4;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1520) {
      return 5;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1640) {
      return 6;
    } else if (this.innerWidth > 1640 && this.innerWidth <= 1920) {
      return 7;
    } else {
      return 8;
    }
  }

  private filterUserReviewProduct(
    slug: string,
    reviewProduct: ProductReviewModel
  ): ProductReviewModel {
    if (
      reviewProduct.author === 'Rafael' &&
      slug === 'shampoo-dia-a-dia-mens'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Diovane Roldi' &&
      slug === 'sabonete-liquido-facial-mens'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Alan'
      && slug === 'cera-ultra-mens')
    {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Fábio' &&
      slug === 'shampoo-refrescante-mens'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Gabriel Gomes' &&
      slug === 'esfoliante-facial-mens'
    ) {
      return reviewProduct;
    }
    // else if (
    //   !reviewProduct.author &&
    //   slug === 'balm-para-barba-mens'
    // ) {
    //   return reviewProduct;
    // }
    else if (
      reviewProduct.author === 'João pontello' &&
      slug === 'hidratante-corporal-mens'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Mateus' &&
      slug === 'condicionador-dia-a-dia-mens'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Rubens' &&
      slug === 'gel-redutor-de-medidas-mens-abdofast'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Tiago C. Araújo' &&
      slug === 'oleo-para-barbas-curtas-mens'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Winderson' &&
      slug === 'pomada-flex-mens'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Jádson' &&
      slug === 'shampoo-barba-e-cabelo-mens-300ml'
    ) {
      return reviewProduct;
    }
    else if (
      reviewProduct.author === 'Arianni' &&
      slug === 'locao-pos-barba-mens'
    ) {
      return reviewProduct;
    }
    else {
      return null;
    }
  }

  private async getReviewedProducts(): Promise<void> {
    const value = `Men's`;
    const productsSlugs = [
      // 'shampoo-dia-a-dia-mens',
      // 'sabonete-liquido-facial-mens',
      'cera-ultra-mens',
      // 'shampoo-refrescante-mens',
      // 'esfoliante-facial-mens',
      // 'balm-para-barba-mens',
      'hidratante-corporal-mens',
      'condicionador-dia-a-dia-mens',
      'gel-redutor-de-medidas-mens-abdofast',
      'oleo-para-barbas-curtas-mens',
      'pomada-flex-mens',
      'shampoo-barba-e-cabelo-mens-300ml', 
      'locao-pos-barba-mens'
    ];
    try {
      const reviewedProductsResponse = await this._fireStore.find({
        filters: {
          brand: {
            operator: Where.EQUALS,
            value
          }
        },
        fields: ["id", "sku", "slug","brand","name", "images", "reviews", "price",]
      });
      console.log(reviewedProductsResponse);
      const filteredReviewedProducts = (product: Product) =>
        productsSlugs.includes(product.slug);

      const reviewedProducts: ProductModel[] = reviewedProductsResponse.data
        .filter(filteredReviewedProducts)
        .map((product) => {
          return {
            id: product.id,
            sku: product.sku,
            name: product.name,
            images: product.images,
            reviews: product.reviews
              .filter((productReview) =>
                this.filterUserReviewProduct(product.slug, productReview)
              )
              .map((productReview) => {
                return {
                  author: productReview.author,
                  email: productReview.email,
                  location: productReview.location,
                  rate: productReview.rate,
                  review: productReview.review,
                  status: productReview.status,
                  title: productReview.title,
                };
              }),
            slug: product.slug,
            tags: product.tags ?? [],
          };
        });

      this.reviewedProducts = reviewedProducts;
      let qtd: number;
      if (this.innerWidth > 2000) {
        qtd = this.reviewedProducts?.length / 4;
      } else {
        qtd = this.reviewedProducts?.length / 4;
      }
      const dots = [];
      for (let i = 0; i < qtd; i++) {
        dots.push(i)
      }
      this.reviewPoints = dots;
      this.loadingReviewedProducts = false;
      this.loadingReviewedProducts = false;
    } catch (err) {
      console.error(err);
      this.loading = false;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  open(content:any) {
    this.modalService.open(content, {centered: true, size: 'xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  } 

  initForm(): void {
    this.formLead = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(this.namePattern)])],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  get formLeadControl() {
    return this.formLead.controls;
  }

  getClass(formLeadControl) {
    if (formLeadControl === this.formLeadControl.name) {
      if (this.formLeadControl.name.valid) {
        return 'success';
      }
      if (
        (this.formLeadControl.name.touched || this.submitted) &&
        (this.formLeadControl.name.errors?.required || this.formLeadControl.name.errors?.pattern)
      ) {
        return 'error';
      } else {
        return '';
      }
    }
    if (formLeadControl === this.formLeadControl.email) {
      if (this.formLeadControl.email.valid) {
        return 'success';
      }
      if (
        (this.formLeadControl.email.touched || this.submitted) &&
        (this.formLeadControl.email.errors?.required || this.formLeadControl.email.errors?.pattern)
      ) {
        return 'error';
      } else {
        return '';
      }
    }
    else {
      return;
    }
  }

  setObject() {
    return {
      name: this.formLead.value.name,
      email: this.formLead.value.email,
      source: LeadSourceEnum.mens,
      acceptsNewsletter: true,
    };
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.formLead.valid) {
      try {
        const requestPayload: LeadModel = this.setObject() as LeadModel;
        this.loadingNewsletter = true;
        await this._brandLeadService
          .registerNewsletter(requestPayload)
          .then((res: ResponseNewletter) => {
            res;
            this.submitted = false;
            this.user_email = requestPayload.email;
            this.formLead.reset();
          });
      } catch (error) {
        console.log(error);
        this.loadingNewsletter = false;
      } finally {
        this.loadingNewsletter = false;
        this.submitted = false;
        this.show = !this.show;
      }
    }
  }

  async mountResult(){
    
    this.results = [
      {
        imagePath: 'assets/img/results/Pomada-Matte.webp',
        name: `Pomada Matte Men's`
      },
      {
        imagePath: 'assets/img/results/Balm.webp',
        name: `Balm para Barba Men's`
      },
      {
        imagePath: 'assets/img/results/Pomada-Flex.webp',
        name: `Pomada Flex Men's`
      },
      {
        imagePath: 'assets/img/results/Cera-Ultra.webp',
        name: `Cera Ultra Men's`
      },
      {
        imagePath: 'assets/img/results/Pente-Curvo.webp',
        name: `Pente Curvo Men's`
      }
    ];
  }
}
