export const environment = {
  API_URL: 'https://subscription-api-prod.glambox.com.br/',
  production: true,
  firebase: {
    apiKey: 'AIzaSyCJf1tkjIN1I1ZegazckSiwS0ik4Lequwo',
    authDomain: 'b4aprodution.firebaseapp.com',
    projectId: 'b4aprodution',
    storageBucket: 'b4aprodution.appspot.com',
    messagingSenderId: '458755317337',
    appId: '1:458755317337:web:fc98697332fada8d021b48',
    measurementId: 'G-T6E6L3J73M',
  },
  firebaseRegion: 'southamerica-east1',
  elasticSearch: {
    url: 'https://e4a30e39227142719915dfb4bd5240b5.us-central1.gcp.cloud.es.io:443',
    credential: 'MnBJVHY0Y0JtbEZ2dFBZRE82NGQ6WHdXQ1ZwTkhRQUNvS2s5WTNGSUNCUQ=='
  },
  instaToken:
    'IGQVJVUmphQm9yVGEzQmctRFo4ZA01rWWliaGFiR0c1Ql9ES00tRU5iV0VXY1hKMjBKMWptMXRqODJjYTg5cE5CaXlhcThySDRDQjdzUVJJSlUxVkJISXVqMlM1dEJBT0RuNE5hOFVLV3pZAVDhVVzFoNAZDZD',
  instaApi: 'https://graph.instagram.com/me/media?access_token=',
  hasura: {
    endpoint: 'https://hasura-products-36m4jjtkna-rj.a.run.app/v1/graphql',
    adminSecret: 'b171ab4d722455b03648ed462b41f8c2'
  },
  apiUrl: 'https://subscription-api-prod.glambox.com.br/'
};
