import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import data from 'src/assets/data/recipe.json';

import { images } from '../../shared/models/images';

@Component({
  selector: 'mens-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  recipes: images[];

  constructor( private meta: Meta,
    private titleService: Title) { 
    this.recipes = data.recipe
  }

  ngOnInit(): void {
    this.titleService.setTitle("Men's: Quem somos e o que fazemos");
    this.meta.addTag({
      name: 'author',
      content: 'B4A'
    });
    this.meta.updateTag({
      name: 'description',
      content:
        `A Men's apresenta uma rotina otimizada com produtos de barba, cabelo, rosto e corpo de qualidade, tecnologia e eficácia, atendendo as necessidades do homem.`
    });
  }

}
