import { Component, Input, OnInit } from '@angular/core';
import { Product } from '@infrab4a/connect';
import { Result } from '../../models/result.model';

@Component({
  selector: 'mens-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss']
})
export class ResultCardComponent implements OnInit {

  @Input() product: Result;

  constructor() { }

  ngOnInit(): void {
  }

  private getRatingAvg(product: Product): number {
    const totalReviews = product.reviews?.filter(r => r.status).length || 0;
    const totalReviewsSum = totalReviews
      ? product.reviews
          ?.filter(r => r.status)
          .reduce((sum, review) => (sum += review.rate), 0) / totalReviews
      : 0;

    return Math.trunc(totalReviewsSum);
  }

  getStarRating(product: Product, index: number) {
    let render = 'outline';

    if (this.getRatingAvg(product) > index) {
      render = 'fill';
    }

    return render;
  }

}
