<div id="reviewed-product-card">
    <div class="header-card">
        <img
            [src]="reviewedProduct.images[0]"
            [alt]="reviewedProduct.name"
        >

        <div class="header-card__body">
            <div class="info">
                <div
                    class="title"
                    ellipsis
                    [ellipsis-content]="reviewedProduct.reviews[0]?.title"
                ></div>

                <div
                    class="info__name"
                    ellipsis
                    [ellipsis-content]="reviewedProduct.name"
                ></div>

            </div>
            <ngb-rating
                [(rate)]="ratingAverage"
                [readonly]="true"
                [starTemplate]="starHalf"
            >
            </ngb-rating>

        </div>
    </div>
    <div class="reviews">
        <div
            class="reviews__review"
            *ngIf="reviewedProduct.reviews[0]?.review"
            ellipsis
        >
            "{{ reviewedProduct.reviews[0].review }}"
        </div>
        <span
            class="reviews__author"
            *ngIf="reviewedProduct.reviews[0]?.author"
        >
            {{ reviewedProduct.reviews[0].author }}
        </span>
    </div>

</div>

<ng-template
    #starHalf
    let-fill="fill"
    let-index="index"
>
    <span
        class="star"
        [class.full]="fill === 100"
    >
        <span
            class="half"
            [style.width.%]="fill"
        >
            &#9733;
        </span>
        &#9733;
    </span>
</ng-template>
