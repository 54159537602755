<div id="mens-newsletter">
    <form
        [formGroup]="formLead"
        (ngSubmit)="onSubmit()"
        *ngIf="show"
        novalidate
    >
        <h3 class="title">Receba ofertas e descontos exclusivos</h3>
        <div class="news-letter">
            <div class="input-box">
                <input
                    [ngClass]="getClass(formLeadControl.name)"
                    id="name"
                    type="text"
                    formControlName="name"
                    placeholder="Digite seu nome"
                >
            </div>
            <div id="input-email" class="input-box">
                <input
                    [ngClass]="getClass(formLeadControl.email)"
                    id="email"
                    type="email"
                    formControlName="email"
                    placeholder="Digite seu e-mail"
                >
                <small
                    class="danger"
                    *ngIf="(formLeadControl.email.touched || submitted) && formLeadControl.email.errors?.required"
                >
                    E-mail é obrigatório
                </small>
                <small
                    class="danger"
                    *ngIf="formLeadControl.email.touched && formLeadControl.email.errors?.pattern"
                >
                    E-mail inválido
                </small>
            </div>
            <button
                type="submit"
                class="btn-primary"
                [disabled]="loading || formLead.invalid"
            >
                {{ loading ? 'Aguarde' : 'Enviar' }}
            </button>
        </div>
    </form>
    <div
        class="success-send"
        *ngIf="!show"
    >
        <h4>Seu e-mail foi cadastrado com sucesso!</h4>
        <p>
            Em breve você receberá um e-mail de confirmação e ofertas especiais.
        </p>
    </div>
</div>
